<template>
  <b-card>
    <form-user :editable="editable" :adm_id="$route.params.id"/>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';
import FormUser from './FormUser.vue';
export default {
    components: {
        BCard,
        FormUser
    },
    data(){
        return{
        editable: true
    }}
}
</script>